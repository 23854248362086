import axios from "axios";
import Message from "rc-message";
import Cookies from "js-cookie";

axios.defaults.timeout = 10000;
axios.defaults.baseURL = `${process.env.BASE_URL}/api/`;
axios.defaults.withCredentials = false;

axios.interceptors.request.use(
  (config) => {
    let token = Cookies.get("token");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    console.log("请求失败");
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 403) {
      Message.info({
        content: "登录已过期，请重新登录",
      });
      window.location.href = "/";
    }
    return response;
  },
  (error) => {
    console.log("请求error", error.message);
    return Promise.reject(error);
  }
);

export default axios;
